import React, { useState, useEffect } from 'react';
import gsap from "gsap";
import { Link } from 'gatsby';

import './Navigation.scss';

// @ts-ignore
import logo from '../../images/logo.svg';
// @ts-ignore
import menuIcon from './assets/menu-icon.svg';
// @ts-ignore
import exitIcon from './assets/exit-icon.svg';


const Navigation = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [location, setLocation] = useState<any>(null);

  useEffect(() => {
    if(typeof window === 'undefined') return;
    setLocation(window.location);
  }, [])

  useEffect(() => {
    //@ts-ignore
    const tl = gsap.timeline({ repeat: false })
    tl.to(
      "#mobile-options", { 
        ease: showMenu ? "power2.in" : "power2.out",
        display: showMenu ? 'flex' : 'none',
        opacity: showMenu ? 1 : 0,
        duration: 0.2
      })
      .to("#links-container a", {
        opacity: showMenu ? 1 : 0,
        ease: showMenu ? "power2.in" : "power2.out",
        duration: 0.15,
        stagger: {
          amount: 0.15
        }
      })
  }, [showMenu])
  
  return (
    <div className="navWrapper">

      {location && location.pathname !== "/" ? (
        <Link className={location && location.pathname === "/" ? "hidden" : null} to="/">
          <img alt="logo" className="logo" src={logo}/>
        </Link>
      ) : <div/>}

      {!showMenu && (
        <img
          onClick={() => setShowMenu(!showMenu)}
          alt="mobile-menu"
          className="openMenu"
          src={menuIcon}
        />
      )}

      <div id="mobile-options" className="mobileOptionsContainer">
        <img
          onClick={() => setShowMenu(!showMenu)}
          className="closeMenu"
          alt="mobile-exit-menu"
          src={exitIcon}
        />

        { location && (
          <div id="links-container" className="mobileLinksContainer">
            {/* <Link to="/about">ABOUT</Link> */}
            <Link
              className={location.pathname === "/work" ? 'isActive' : ''}
              to="/work">
                WORK
            </Link>
            <a href="mailto:sduval215@gmail.com">
                CONTACT
            </a>
          </div>
        )}
      </div>

      { location && (
        <div className="linksContainer">
          {/* <Link to="/about">ABOUT</Link> */}
          <Link className={location.pathname === "/work" ? 'isActive' : ''} to="/work">WORK</Link>
          <a href="mailto:sduval215@gmail.com">CONTACT</a>
        </div>
      )}
    </div>
  )
}

export default Navigation;
