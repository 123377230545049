import React from 'react';

import { Helmet } from 'react-helmet';

export const setMetaData = (title: string) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Steven Duval Ruilova - Lead Engineer @Siberia"
      />
      <meta
        property="og:image"
        content="https://images.prismic.io/pstv-2/9ee1b8f4-f1a9-4b2c-9df4-c1af0e65ded6_sdr-social.png"
      />
    </Helmet>
  )
}